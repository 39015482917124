import { MODALS } from "~/stores/appModals";

const CHECK_APP_INSTALLED_INTERVAL = 1000;
const CHECK_APP_HB_LIMIT = 10;
const DEFAULT_PWA_LINK = "/today?src=pwa";

type IIosPwaError = "iosWrongOs" | "iosWrongBrowser" | "iosPwaNotInstalled";

type IIosPwaErrors = Record<IIosPwaError, boolean>;

interface IPwaState {
  installPromptBannerOpen: boolean;
  deferredInstallPrompt: any | null;
  appInstalled: boolean;
  installInProgress: boolean;
  showIosTutorial: boolean;
}

export const usePwaStore = defineStore("pwa", () => {
  const state = reactive<IPwaState>({
    installPromptBannerOpen: false,
    deferredInstallPrompt: null,
    appInstalled: false,
    installInProgress: false,
    showIosTutorial: false,
  });
  const iosErrors = reactive<IIosPwaErrors>({
    iosWrongOs: false,
    iosPwaNotInstalled: true,
    iosWrongBrowser: false,
  });
  const modalsStore = useAppModalsStore();
  const platformStore = usePlatformStore();
  const { send } = useAnalytics();
  const { isApp } = useQueryState();

  const checkAppInstall = async (): Promise<boolean> => {
    if (typeof navigator !== "undefined") {
      const typelessNavigator = navigator as any;
      const related = await typelessNavigator.getInstalledRelatedApps();
      return related && related.length > 0;
    }
    return false;
  };
  const showPrompt = async (source = "") => {
    const isOnboarding = source === "onboarding";
    if (state.deferredInstallPrompt && !isApp.value) {
      if (isOnboarding) {
        send("Onboarding:Install:Prompt:View");
      }
      addEventListener("appinstalled", () => {
        let counter = 0;
        const appCheckHeartbeat = setInterval(async () => {
          if (counter < CHECK_APP_HB_LIMIT && (await checkAppInstall())) {
            clearInterval(appCheckHeartbeat);
            state.installPromptBannerOpen = false;
            state.appInstalled = true;
            state.installInProgress = false;
            if (!platformStore.platformInfo.pwa) {
              modalsStore.open(MODALS.InstallSuccess);
              if (isOnboarding) {
                send("Onboarding:Install:Finish:View");
              }
            }
          } else if (counter >= CHECK_APP_HB_LIMIT) {
            clearInterval(appCheckHeartbeat);
            state.installPromptBannerOpen = false;
            state.installInProgress = false;
          }
          counter++;
        }, CHECK_APP_INSTALLED_INTERVAL);
      });
      const promptResult = await state.deferredInstallPrompt.prompt();
      if (promptResult.outcome === "dismissed" && typeof window !== "undefined") {
        if (isOnboarding) {
          send("Onboarding:Install:Prompt:Cancel");
        }
        window.location.reload();
      } else if (promptResult.outcome === "accepted") {
        state.installInProgress = true;
        if (isOnboarding) {
          send("Onboarding:Install:Prompt:Ok");
        }
      }
    }
  };

  watch(
    () => platformStore.platformInfo,
    (next) => {
      if (next.osName === "ios") {
        iosErrors.iosWrongOs = !next.pushSupportingIosVersion;
        iosErrors.iosWrongBrowser = next.browserName !== "safari";
        iosErrors.iosPwaNotInstalled = !next.pwa;
      } else {
        iosErrors.iosWrongOs = false;
        iosErrors.iosPwaNotInstalled = true;
        iosErrors.iosWrongBrowser = false;
      }
    },
  );

  return {
    state,
    showPrompt,
    checkAppInstall,
    DEFAULT_PWA_LINK,
  };
});
